<template>
    <div>
        <div class="retrievalpanel">
            <div>
                <div style="display:inline-block;">
                    <OpRadioGroup
                        v-model="query.listdiv"
                        v-slot="{ item }"
                        :choices="radiolist"
                        :itemKey="getListDivKey"
                        required
                        hideheader
                    >
                        {{ item.name }}
                    </OpRadioGroup>
                </div>
                <OpSearchTextField
                    v-model.trim="query.words"
                    label="件名 / ID / 備考"
                    maxlength="80"
                    @changed-query="searchWord"
                    @input="inputedSerchWord"
                />

                <div style="display:inline-block;">
                    <div style="display:inline-block;">
                        <OpSelectFiscalyear
                            v-model="query.fiscalyear"
                            label="会計年度"
                            @input="search"
                            :company="getCompany()"
                        />
                        <!-- <OpSelectArray
                            v-model="query.fiscalyear"
                            label="会計年度"
                            :modelList="getFiscalyearList()"
                            maxwidth="15rem"
                            @input="search"
                            nosudgest
                        /> -->
                    </div>
                    <div style="display:inline-block;">
                        <!-- <OpMultiSelector
                            v-model="query.perioddiv"
                            v-slot="{ item }"
                            :list="perioddivlist"
                            :itemKey="getPeriodDivKey"
                        >
                            {{ item.name }}
                        </OpMultiSelector> -->
                        <OpRadioGroup
                            v-model="query.perioddiv"
                            v-slot="{ item }"
                            :choices="perioddivlist"
                            :itemKey="item => item.key"
                        >
                            {{ item.name }}
                        </OpRadioGroup>
                    </div>
                </div>
                <div style="display:inline-block;">
                    <!-- 部署 -->
                    <div style="display:inline-block;padding:0 10px;">
                        <OpSelectDepartment
                            label="案件担当部署"
                            v-model="query.department"
                            name="department"
                            type="short"
                            div="-1"
                            @input="search"
                        />
                    </div>
                    <!-- 取引先 -->
                    <div style="display:inline-block;padding:0 10px;">
                        <OpSelectCustomer
                            label="取引先（受注先 / ユーザ / 発注先）"
                            v-model="query.customer"
                            name="customer"
                            @input="search"
                        />
                    </div>

                    <button
                        @click="showFilter = true"
                        style="margin-left:1rem; margin-top:1rem; vertical-align: top;"
                    >
                        <IconBase iconName="filter" :visibleName="true"
                            ><IconFilter
                        /></IconBase>
                    </button>
                </div>
            </div>
            <div class="soposts">
                <!-- 受注ステータス -->
                <div class="item">
                    <OpDivMultiSelector
                        label="受注ステータス"
                        v-model="query.salesorder_status"
                        :divlist="divs['sosts']"
                        name="sosts"
                        divViewType="status"
                        @input="search"
                    />
                </div>
                <!-- 発注ステータス -->
                <div class="item">
                    <OpDivMultiSelector
                        label="発注ステータス"
                        v-model="query.purchaseorder_status"
                        :divlist="divs['posts']"
                        name="posts"
                        divViewType="status"
                        @input="search"
                    />
                </div>
            </div>
        </div>
        <div class="list-status">
            <slot></slot>
        </div>
        <OpModal
            v-if="showFilter"
            @close="showFilter = false"
            title="フィルター"
        >
            <div class="detail-filters">
                <!-- 案件ID -->
                <!-- 受注先 -->
                <div class="item op-cust">
                    <OpSelectCustomer
                        label="受注先"
                        v-model="query.salesorder_customer"
                        name="salesorder_customer"
                    />
                </div>
                <!-- ユーザ -->
                <div class="item op-user">
                    <OpSelectCustomer
                        label="ユーザ"
                        v-model="query.user_customer"
                        name="user_customer"
                    />
                </div>
                <!-- 案件担当部署 -->
                <div class="item op-dept">
                    <OpSelectDepartment
                        label="案件担当部署"
                        v-model="query.opportunity_department2"
                        name="opportunity_department2"
                        type="short"
                        div="-1"
                    />
                </div>

                <!-- 受注ステータス -->
                <div class="item so-sts">
                    <OpDivMultiSelector
                        label="受注ステータス"
                        v-model="query.salesorder_status"
                        :divlist="divs['sosts']"
                        name="sosts"
                        divViewType="status"
                    />
                </div>
                <!-- 受注担当者 -->
                <div class="item so-emp">
                    <OpSelectEmployee
                        label="受注担当社員"
                        v-model.trim="query.salesorder_employee"
                        maxlength="40"
                        name="salesorder_employee_id"
                    />
                </div>

                <!-- 案件備考 -->

                <!-- 受注ID -->
                <!-- ＊受注先 -->

                <!-- 受注ステータス -->
                <!-- 検収日 -->
                <!-- 請求書発行区分 -->
                <div class="item so-invissued">
                    <OpDivMultiSelector
                        label="請求書発行済区分"
                        v-model="query.invoice_issued_div"
                        :divlist="divs['invissued']"
                        name="invoice_issued_div"
                        divViewType="short"
                    />
                </div>
                <!-- 売上日 検収日-->
                <div class="item so-apt">
                    <OpDateRangeTextField
                        label="検収日"
                        v-model="query.acceptance_date"
                        name="acceptance_date"
                    />
                </div>

                <!-- 入金区分 -->
                <div class="item so-deptdiv">
                    <OpDivMultiSelector
                        label="入金済区分"
                        v-model="query.deposit_div"
                        :divlist="divs['dep']"
                        name="deposit_div"
                        divViewType="short"
                    />
                </div>
                <!-- 入金日 -->
                <div class="item so-deptdate">
                    <OpDateRangeTextField
                        label="入金日"
                        v-model="query.deposit_date"
                        name="deposit_date"
                    />
                </div>

                <!-- 検収日/売上日の一致／不一致 -->
                <div class="item acpt-sls">
                    <OpMultiSelector
                        label="検収日と売上日の値"
                        v-model="query.acpt_sls"
                        v-slot="{ item }"
                        :list="acpt_sls_eq_list"
                        :itemKey="item => item.key"
                    >
                        {{ item.name }}
                    </OpMultiSelector>
                </div>

                <!-- 売上 -->
                <div class="item sls-sts">
                    <OpMultiSelector
                        label="売上登録状況"
                        v-model="query.sales_status"
                        v-slot="{ item }"
                        :list="sales_status_list"
                        :itemKey="item => item.key"
                    >
                        {{ item.name }}
                    </OpMultiSelector>
                </div>
                <!-- 受注備考 -->

                <!-- 発注ステータス -->
                <div class="item po-sts">
                    <OpDivMultiSelector
                        label="発注ステータス"
                        v-model="query.purchaseorder_status"
                        :divlist="divs['posts']"
                        name="posts"
                        divViewType="status"
                    />
                </div>

                <!-- 発注ID -->
                <!-- 発注先 -->
                <div class="item po-cust">
                    <OpSelectCustomer
                        label="発注先"
                        v-model="query.purchaseorder_customer"
                        name="purchaseorder_customer"
                    />
                </div>
                <!-- 発注担当者 -->
                <div class="item po-emp">
                    <OpSelectEmployee
                        label="発注担当者"
                        v-model.trim="query.purchaseorder_employee"
                        maxlength="40"
                        name="purchaseorder_employee"
                    />
                </div>
                <!-- 請求書受領区分 -->
                <div class="item po-recdiv">
                    <OpDivMultiSelector
                        label="請求書受領区分"
                        v-model="query.invoice_receipt_div"
                        :divlist="divs['invrcpt']"
                        name="invoice_receipt_div"
                        divViewType="short"
                    />
                </div>
                <!-- 買掛日 納品日-->
                <div class="item po-delidate">
                    <OpDateRangeTextField
                        label="納品日"
                        v-model.trim="query.delivery_date"
                        name="delivery_date"
                    />
                </div>
                <!-- 支払区分  -->
                <div class="item po-paydiv">
                    <OpDivMultiSelector
                        label="支払区分"
                        v-model="query.payment_div"
                        :divlist="divs['pmt']"
                        name="payment_div"
                        divViewType="short"
                    />
                </div>
                <!-- 支払日 -->
                <div class="item po-paydate">
                    <OpDateRangeTextField
                        label="支払日"
                        v-model="query.payment_date"
                        name="payment_date"
                    />
                </div>

                <!-- 発注備考 -->

                <!-- 納品日/計上日の一致／不一致 -->
                <div class="item delv-cos">
                    <OpMultiSelector
                        label="納品日と計上日の値"
                        v-model="query.delv_cos"
                        v-slot="{ item }"
                        :list="delv_cos_eq_list"
                        :itemKey="item => item.key"
                    >
                        {{ item.name }}
                    </OpMultiSelector>
                </div>

                <!-- 原価 -->
                <div class="item cos-sts">
                    <OpMultiSelector
                        label="原価登録状況"
                        v-model="query.costofsales_status"
                        v-slot="{ item }"
                        :list="costofsales_status_list"
                        :itemKey="item => item.key"
                    >
                        {{ item.name }}
                    </OpMultiSelector>
                </div>
            </div>
            <div class="control-box">
                <button class="control-button" @click="cancel">
                    キャンセル
                </button>
                <button class="control-button" @click="setFilter">
                    設定
                </button>
                <button class="control-button" @click="clearFilter">
                    クリア
                </button>
            </div>
        </OpModal>
    </div>
</template>
<script>
import OpSearchTextField from "@/components/OpSearchTextField.vue";
import OpDivMultiSelector from "@/components/OpDivMultiSelector.vue";
import OpMultiSelector from "@/components/OpMultiSelector.vue";
import OpSelectCustomer from "@/components/OpSelectCustomer.vue";
import OpSelectDepartment from "@/components/OpSelectDepartment.vue";
import OpSelectEmployee from "@/components/OpSelectEmployee.vue";
import IconBase from "@/components/icons/IconBase.vue";
import IconFilter from "@/components/icons/IconFilter.vue";
import OpModal from "@/components/OpModal.vue";
import OpDateRangeTextField from "@/components/OpDateRangeTextField.vue";
import OpRadioGroup from "@/components/OpRadioGroup.vue";
import OpSelectFiscalyear from "@/components/OpSelectFiscalyear.vue";

export default {
    name: "OpportunityQuery",
    model: {
        prop: "querys"
    },
    props: {
        querys: Object
    },
    components: {
        OpSearchTextField,
        OpDivMultiSelector,
        OpMultiSelector,
        OpSelectCustomer,
        OpSelectDepartment,
        IconBase,
        IconFilter,
        OpModal,
        OpSelectEmployee,
        OpDateRangeTextField,
        OpRadioGroup,
        OpSelectFiscalyear
    },
    data: function() {
        return {
            query: this.initQuery(this.querys),
            radiolist: [
                { key: "op", name: "案件" },
                { key: "so", name: "受注" },
                { key: "po", name: "発注" }
            ],
            myid: null,
            showFilter: false,
            fiscalyears: null,
            perioddivlist: [
                { key: 0, name: "通期" },
                { key: 1, name: "上期" },
                { key: 2, name: "下期" }
            ],
            tempSerchWord: null,
            sales_status_list: [
                { key: 1, name: "要修正" },
                { key: 2, name: "受注不一致" },
                { key: 4, name: "受注一致" }
            ],
            costofsales_status_list: [
                { key: 1, name: "要修正" },
                { key: 2, name: "発注不一致" },
                { key: 4, name: "発注一致" }
            ],
            acpt_sls_eq_list: [
                { key: 1, name: "全て一致" },
                { key: 2, name: "不一致あり" }
            ],
            delv_cos_eq_list: [
                { key: 1, name: "全て一致" },
                { key: 2, name: "不一致あり" }
            ]
        };
    },
    watch: {
        "query.listdiv": function() {
            this.search(this.query.words);
        },
        "query.perioddiv": function() {
            this.search(this.query.words);
        },
        "query.department.department_id": function() {
            this.query.opportunity_department2 = this.query.department;
        }
    },
    methods: {
        initQuery: function(q) {
            let ret = { ...q };
            return ret;
        },
        inputedSerchWord(val) {
            this.tempSerchWord = val;
        },
        searchWord: function(val) {
            this.query.words = val;
            this.tempSerchWord = val;
            this.$emit("changed-query", { ...this.query });
        },
        search: function() {
            if (!this.query.fiscalyear) {
                this.query.perioddiv = null;
            }
            this.query.words = this.tempSerchWord;
            this.$emit("changed-query", { ...this.query });
        },
        cancel() {
            this.showFilter = false;
            this.query = this.initQuery(this.querys);
        },
        setFilter() {
            this.query.department = this.query.opportunity_department2;
            this.$emit("changed-query", { ...this.query });
            this.showFilter = false;
        },
        clearFilter: function() {
            let w = this.query.words;
            let v = this.query.listdiv;
            let f = this.query.fiscalyear;
            this.query = {
                words: w,
                listdiv: v,
                payment_div: null,
                fiscalyear: f
            };
        },
        getListDivKey: function(item) {
            return item.key;
        },
        getPeriodDivKey: function(item) {
            return item.key;
        }
    },
    created: async function() {
        if (!this.myid) {
            this.$store.commit("inclementIdseq");
            this.myid = "oppo2020" + this.$store.state.idseq;
        }
        this.tempSerchWord = this.query.words;
        await this.loaddivs(
            "sosts",
            "can",
            "invissued",
            "dep",
            "pmt",
            "posts",
            "invrcpt"
        );
    }
};
</script>

<style scoped>
.control-box {
    display: grid;
    border-top: 1px solid var(--border-color);
    padding: 10px 0;
    grid-template-columns: 200px 1fr 200px;
    justify-items: center;
}
.retrievalpanel {
    padding: 2px 50px;
    background-color: var(--glass-pane-bg-color);
    border-bottom: 1px solid var(--border-color);
}
.retrievalpanel > div {
    display: inline-block;
    padding: 0px 5px;
}
.retrievalpanel > div > * {
    display: inline-block;
    padding: 0px 5px;
}
.retrievalpanel > *:first-child {
    padding-left: 50px;
}
.retrievalpanel .soposts > *:first-child {
    padding-right: 2rem;
}

.list-status {
    background-color: var(--glass-pane-bg-color);
    display: block;
}

/* --- */

.detail-filters {
    display: grid;
    width: 920px;
    /* grid-template-rows: 60px 60px; */
    grid-template-columns: 3fr 1fr 2fr 2fr 2fr 2fr;
    grid-template-areas:
        "op-cust op-cust  op-user op-user  op-dept  op-dept"
        "so-sts  so-sts   so-sts  so-emp   so-emp   so-emp"
        "so-inv  so-apt   so-apt   so-apt   acpt-sls acpt-sls"
        "so-ddiv so-ddate  so-ddate so-ddate sls-sts sls-sts"
        "po-sts  po-sts    po-sts  po-sts   po-sts   po-sts"
        "po-cust po-cust  po-cust po-emp po-emp po-emp"
        "po-recdiv po-delidate po-delidate po-delidate delv-cos delv-cos"
        "po-paydiv po-paydate po-paydate po-paydate cos-sts cos-sts";
    grid-row-gap: 20px;
    grid-column-gap: 10px;
}
.detail-filters .op-cust {
    grid-area: op-cust;
}
.detail-filters .op-user {
    grid-area: op-user;
}
.detail-filters .op-dept {
    grid-area: op-dept;
}
.detail-filters .so-apt {
    grid-area: so-apt;
}
.detail-filters .so-sts {
    grid-area: so-sts;
}
.detail-filters .po-sts {
    grid-area: po-sts;
}
.detail-filters .so-emp {
    grid-area: so-emp;
}
.detail-filters .so-invissued {
    grid-area: so-inv;
}
.detail-filters .so-deptdate {
    grid-area: so-ddate;
}
.detail-filters .so-deptdiv {
    grid-area: so-ddiv;
}
.detail-filters .po-cust {
    grid-area: po-cust;
}
.detail-filters .po-emp {
    grid-area: po-emp;
}
.detail-filters .po-recdiv {
    grid-area: po-recdiv;
}
.detail-filters .po-delidate {
    grid-area: po-delidate;
}
.detail-filters .po-invdiv {
    grid-area: po-invdiv;
}
.detail-filters .po-paydate {
    grid-area: po-paydate;
}
.detail-filters .po-paydiv {
    grid-area: po-paydiv;
}
.detail-filters .sls-sts {
    grid-area: sls-sts;
}
.detail-filters .cos-sts {
    grid-area: cos-sts;
}
.detail-filters .acpt-sls {
    grid-area: acpt-sls;
}
.detail-filters .delv-cos {
    grid-area: delv-cos;
}
</style>
