<template>
    <div>
        <OpSelect
            v-model="dataModel"
            :suggest="suggestEmp"
            :suggestValue="suggestEmpValue"
            :suggestKey="suggestEmpKey"
            :required="required"
            :name="name"
            :id="id"
            :label="label"
            :maxwidth="maxwidth"
            @changed-valid="changedValid"
        />
    </div>
</template>

<script>
export default {
    name: "OpSelectEmployee",
    model: {
        prop: "inputModel",
        event: "input"
    },
    props: {
        inputModel: Object,
        required: Boolean,
        name: String,
        id: String,
        maxwidth: String,
        label: String
    },
    data: function() {
        return {
            dataModel: this.inputModel,
            modelChange: false
        };
    },
    watch: {
        dataModel: function(value) {
            if (this.modelChange) {
                this.modelChange = false;
                return;
            }
            if (value == "") {
                value = null;
            }
            this.$emit("input", value);
        },
        inputModel: function(val) {
            this.modelChange = true;
            this.dataModel = { ...val };
        }
    },
    methods: {
        suggestEmp: async function(query, result) {
            result.length = 0;
            let qs = query.split(" ");
            const qc = qs.length;
            const elist = await this.getEmployeeList();
            result.length = 0;
            if (elist) {
                const ITEMMAX = 20;
                let cnt = 0;
                for (const ex in elist) {
                    let qx = qc;
                    let emp = elist[ex];
                    for (const q of qs) {
                        if (
                            emp.employee_id.includes(q) ||
                            emp.employee_name_long.includes(q)
                        ) {
                            qx--;
                        }
                    }
                    if (qx < 1) {
                        result.push(emp);
                        cnt++;
                    }
                    if (cnt >= ITEMMAX) {
                        break;
                    }
                }
            }
        },
        suggestEmpValue: function(emp) {
            let ret = "";
            if (emp != null && emp.employee_id != undefined) {
                ret = emp.employee_id + " " + emp.employee_name_long;
            }
            return ret;
        },
        suggestEmpKey: function(emp) {
            return emp.employee_id;
        },
        changedValid: function(name, flag) {
            this.$emit("changed-valid", name, flag);
        },
        getEmployeeList: async function() {
            let emplist = this.$store.state.employeelist;
            const ts = emplist.timestamp;
            let ret = emplist.list;
            const now = Date.now();
            if (ts == null || now - ts > 60000) {
                await this.loadList(this.makeurl("emp"), d => {
                    let eobj = {
                        timestamp: now,
                        list: d
                    };
                    this.$store.commit("employeelist", eobj);
                    ret = d;
                });
            }
            return ret;
        }
    },
    created: function() {
        // this.getEmployeeList();
    }
};
</script>
