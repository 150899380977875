<template>
    <div class="salesorder-retrieved">
        <div style="position:relative;">
            <div class="loader" v-if="executing" key="executing">
                Now loading...
            </div>
            <transition name="strans" mode="out-in">
                <table
                    class="so-r-list"
                    v-if="!executing && payload_data.length > 0"
                >
                    <tbody
                        v-for="(d, ix) in payload_data"
                        :key="d.salesorder_id"
                    >
                        <tr>
                            <th class="rownum">
                                <button
                                    class="actionButton"
                                    @click="showView(d)"
                                >
                                    {{ ix + 1 + listStatus.offset }}
                                </button>
                            </th>
                            <td data-label="案件" class="op">
                                <div class="title">
                                    案件
                                    <span class="id">{{
                                        d.opportunity_id
                                    }}</span>
                                </div>
                                <div class="value">
                                    {{ d.opportunity_subject }}
                                </div>
                            </td>
                            <td data-label="担当部署" class="op-dept">
                                <div class="title">
                                    担当部署
                                </div>
                                <div class="value">
                                    {{ deptName(d.opportunity_department_id) }}
                                </div>
                            </td>
                            <td data-label="受注" class="so">
                                <div class="title">
                                    <span class="soid">{{
                                        d.salesorder_id
                                    }}</span>
                                </div>
                                <div class="value">
                                    {{ d.salesorder_subject }}
                                </div>
                            </td>
                            <td data-label="受注先" class="so-cust">
                                <div class="title">
                                    受注先
                                    <span class="code">{{
                                        d.salesorder_customer_code
                                    }}</span>
                                </div>
                                <div class="value">
                                    {{ d.salesorder_customer_name_short }}
                                    <small>{{
                                        d.salesorder_customer_name_long
                                    }}</small>
                                </div>
                            </td>
                            <td data-label="受注ステータス" class="so-status">
                                <div class="title">
                                    受注ステータス
                                </div>
                                <div class="value">
                                    <span class="statusValue"
                                        >{{ d.salesorder_status }}
                                        <div class="statusName">
                                            {{
                                                divName(
                                                    "sosts",
                                                    d.salesorder_status
                                                )
                                            }}
                                        </div>
                                    </span>
                                </div>
                            </td>
                            <td data-label="発注ステータス" class="po-status">
                                <div class="title">
                                    発注ステータス
                                </div>
                                <div class="value">
                                    <span
                                        v-for="(v, c) in viewStsDiv(
                                            'posts',
                                            d.purchaseorder_status
                                        )"
                                        :key="c"
                                    >
                                        <span class="statusValue"
                                            >{{ c }}
                                            <div class="statusName">
                                                {{ v }}
                                            </div>
                                        </span>
                                    </span>
                                </div>
                            </td>
                            <!-- <td data-label="受注担当者" class="so-emp">
                                <div class="title">
                                    受注担当者
                                    <span class="id">{{
                                        d.salesorder_employee_id
                                    }}</span>
                                </div>
                                <div class="value">
                                    {{ d.salesorder_employee_name_long }}
                                </div>
                            </td> -->
                            <td data-label="ユーザ" class="so-emp">
                                <div class="title">
                                    ユーザ
                                    <span class="code">{{
                                        d.user_customer_code
                                    }}</span>
                                </div>
                                <div class="value">
                                    {{ d.user_customer_name_short }}
                                    <small>{{
                                        d.user_customer_name_long
                                    }}</small>
                                </div>
                            </td>
                            <td data-label="検収日" class="acpt-date">
                                <div class="title">
                                    検収日
                                </div>
                                <div class="value">
                                    {{ d.acceptance_date | date }}
                                </div>
                            </td>
                            <td data-label="請求書発行" class="inv-issued">
                                <div class="title">
                                    請求書発行
                                </div>
                                <div class="value">
                                    {{
                                        viewDiv(
                                            "invissued",
                                            d.invoice_issued_div
                                        )
                                    }}
                                </div>
                            </td>
                            <td data-label="入金日" class="dep-date">
                                <div class="title">
                                    入金日
                                </div>
                                <div class="value">
                                    {{ d.deposit_date | date }}
                                </div>
                            </td>
                            <td data-label="入金" class="dep-div">
                                <div class="title">
                                    入金
                                </div>
                                <div class="value">
                                    {{ viewDiv("dep", d.deposit_div) }}
                                </div>
                            </td>
                            <td data-label="受注金額" class="so-amt amount">
                                <div class="title">
                                    受注金額
                                </div>
                                <div class="value">
                                    {{ d.salesorder_amount | amount }}
                                </div>
                            </td>

                            <td data-label="発注金額" class="sopo-amt amount">
                                <div class="title">
                                    発注金額
                                </div>
                                <div class="value">
                                    {{
                                        d.salesorder_purchaseorder_amount
                                            | amount
                                    }}
                                </div>
                            </td>
                            <td
                                data-label="内部売上"
                                class="so-sopo-amt amount"
                            >
                                <div class="title">
                                    内部売上
                                </div>
                                <div class="value">
                                    {{
                                        (d.salesorder_amount -
                                            d.salesorder_purchaseorder_amount)
                                            | amount
                                    }}
                                </div>
                            </td>
                            <td data-label="売上" class="so-sls">
                                <div class="value">
                                    <div
                                        v-if="d.sls_status == -1"
                                        class="sls-mark warn"
                                    >
                                        ≠ 売上金額
                                    </div>
                                    <div
                                        v-else-if="d.sls_status > 1"
                                        class="sls-mark notice"
                                    >
                                        売上 {{ d.sls_status }} 件
                                    </div>
                                    <div
                                        v-else-if="d.sls_status == -2"
                                        class="sls-mark notice"
                                    >
                                        ≠ 売上部署
                                    </div>
                                    <div
                                        v-else-if="d.sls_status == -3"
                                        class="sls-mark notice"
                                    >
                                        ≠ 売上計上日
                                    </div>
                                </div>
                            </td>
                            <!-- <td data-label="売上部署" class="sls-depts">
                                <div class="title">
                                    売上部署
                                </div>
                                <div class="value">
                                    {{ d.sls_depts }}
                                </div>
                            </td>
                            <td data-label="売上計上日" class="sls-rec-dates">
                                <div class="title">
                                    売上計上日
                                </div>
                                <div class="value">
                                    <span
                                        v-for="(ms,
                                        yy,
                                        x1) in d.sls_rec_dates_dict"
                                        :key="x1"
                                    >
                                        <span v-if="x1 > 0">, </span>
                                        <sup> {{ yy }}</sup>
                                        <span
                                            v-for="(ds, mm, x2) in ms"
                                            :key="x2"
                                        >
                                            <span v-if="x2 > 0">, </span>
                                            {{ mm }}
                                            
                                        </span>
                                    </span>
                                </div>
                                <div v-if="d.op_dept_id != d.sls_depts">
                                    案件担当部署と異なる部署の売上があります。
                                </div>
                                <div v-if="d.sls_count > 1">
                                    売上が複数件に分割されています。
                                </div>
                            </td> -->
                        </tr>
                    </tbody>
                </table>
                <div v-if="inited && !executing && payload_data.length < 1">
                    該当するデータが見つかりません。
                </div>
            </transition>
        </div>

        <MopSortSelectorModal
            v-if="listStatus.showSortModal"
            title="並べ替え"
            :columns="{
                soid: '受注ID',
                opid: '案件ID',
                soctcd: '受注先コード',
                soempid: '受注担当者ID',
                sosts: '受注ステータス',
                acdt: '検収日',
                invidv: '請求書発行区分',
                depdt: '入金日',
                depdv: '入金区分',
                soamt: '受注金額',
                poamt: '発注金額',
                remamt: '内部売上金額'
            }"
            :orders="query.soOrders"
            @cancel="listStatus.showSortModal = false"
            @decide="sortList"
        />
    </div>
</template>

<script>
import MopSortSelectorModal from "@/components/MopSortSelectorModal.vue";
import DateUtils from "@/DateUtils";

export default {
    name: "SalesorderRetrieved",
    components: {
        MopSortSelectorModal
    },
    model: {
        prop: "query"
    },
    props: {
        query: Object,
        makeParams: Function,
        listStatus: Object
    },
    data: function() {
        return {
            words: "",
            payload_data: [],
            executing: false,
            inited: false,
            wait_exec_timeid: 0
        };
    },
    watch: {
        query: function() {
            this.reserveExecute();
        }
    },
    methods: {
        showView: function(data) {
            this.sy = scrollY;
            this.routePush({
                name: "Opportunity",
                params: { opid: data.opportunity_id, soid: data.salesorder_id }
            });
        },
        viewDiv: function(divkey, code) {
            let ret = code;
            let div = this.divs[divkey][code];
            if (div != null) {
                ret = div.contentshort;
            }
            return ret;
        },
        viewStsDiv: function(divkey, code) {
            let ret = {};
            if (code) {
                let len = code.length;
                const divdict = this.divs[divkey];
                for (let i = 0; i < len; i++) {
                    let c = code.charAt(i);
                    const v = divdict[c];
                    if (v) {
                        ret[c] = v.contentshort;
                    } else {
                        ret[c] = c;
                    }
                }
            }
            return ret;
        },
        divName: function(divkey, code) {
            let ret = "";
            let div = this.divs[divkey][code];
            if (div != null) {
                ret = div.contentshort;
            }
            return ret;
        },
        deptName: function(id) {
            let ret = "";
            let dept = this.department_all_for_list[id];
            if (dept != null) {
                ret = dept.department_name_short;
            }
            return ret;
        },

        reserveExecute: function() {
            if (this.wait_exec_timeid > 0) {
                clearTimeout(this.wait_exec_timeid);
                this.wait_exec_timeid = 0;
            }

            let sec = 500;
            if (this.executing) {
                sec = 1000;
            }

            this.wait_exec_timeid = setTimeout(() => {
                this.wait_exec_timeid = 0;
                this.execute();
            }, sec);
        },

        execute: function(scy) {
            this.executing = true;
            this.readDataToArray(
                this.makeurl("so", this.makeParams(this.query.soOrders)),
                (list, hd, count, limit, offset) => {
                    this.payload_data = list;
                    this.listStatus.count = count;
                    this.listStatus.limit = limit;
                    this.listStatus.offset = offset;

                    for (let item of this.payload_data) {
                        item["sls_status"] = this.howSls(item);
                    }
                },
                null,
                null,
                "oppotunitylist"
            ).finally(() => {
                if (this.wait_exec_timeid == 0) {
                    this.executing = false;
                    if (scy) {
                        setTimeout(() => {
                            scrollTo(0, scy);
                        }, 500);
                    }
                }
            });
        },
        sortList: function(orders) {
            this.listStatus.showSortModal = false;
            this.query.soOrders.length = 0;
            this.query.soOrders.push(...orders);
            // this.execute();
            this.reserveExecute();
        },

        howSls: function(d) {
            let ret = 0;
            if (d.sls_amt != d.salesorder_amount) {
                ret = -1;
            } else if (d.sls_count > 1) {
                ret = d.sls_count;
            } else if (d.opportunity_department_id != d.sls_depts) {
                ret = -2;
            } else if (
                d.sls_rec_dates != DateUtils.toString8(d.acceptance_date)
            ) {
                ret -= 3;
            }

            return ret;
        }
    },
    created: async function() {
        this.inited = false;
        await this.loaddivs("sosts", "posts", "invissued", "dep");
        await this.loadDepartmentAllForList();
        await this.execute(this.$route.meta.scrollY);
        this.$route.meta.scrollY = 0;
        this.inited = true;
    },
    filters: {
        yyyy: function(value) {
            let ret = value;
            let v = value;
            if (!(v instanceof Date) && typeof v == "string") {
                v = new Date(v);
            }
            if (v instanceof Date) {
                ret = v.getFullYear();
            }
            return ret;
        },
        mm: function(value) {
            let ret = value;
            let v = value;
            if (!(v instanceof Date) && typeof v == "string") {
                v = new Date(v);
            }
            if (v instanceof Date) {
                ret = ("0" + (v.getMonth() + 1)).slice(-2);
            }
            return ret;
        },
        dd: function(value) {
            let ret = value;
            let v = value;
            if (!(v instanceof Date) && typeof v == "string") {
                v = new Date(v);
            }
            if (v instanceof Date) {
                ret = ("0" + v.getDate()).slice(-2);
            }
            return ret;
        }
    }
};
</script>

<style scoped>
.salesorder-retrieved {
    position: relative;
}
.salesorder-retrieved > .header {
    position: sticky;
    top: 80px;
    z-index: 2;
}
.salesorder-retrieved > .header h1 {
    display: inline-block;
}
.salesorder-retrieved > .header .sortbutton {
    float: right;
}

thead {
    display: none;
}
td {
    vertical-align: top;
    border-right-style: none;
    border-left-style: none;
}
td .title {
    font-size: 0.5em;
    display: block;
}
td .value {
    padding: 3px 0;
    width: 100%;
}
td .value small {
    font-size: x-small;
    display: inline-block;
    color: var(--text-weak-color);
}
td .value .id {
    font-size: 0.75em;
    display: inline-block;
}
td .value .id:after {
    content: " : ";
}
tbody:nth-child(2n + 1) {
    background: var(--odd-bg-color);
}

.so-r-list {
    position: relative;
    width: calc(100% - 20px);
}
.so-r-list tbody {
    display: block;
    border-bottom: 1px solid var(--border-color);
}

.so-r-list tbody tr {
    display: grid;
    /* grid-template-rows: 60px 60px; */
    grid-template-columns: 50px 3fr 80px 2fr 110px 100px 100px 120px 120px;
    grid-template-areas:
        "rownum so    so      so-cust so-status acpt-date   dep-date   so-amt        so-sls"
        "rownum op    op-dept so-emp  po-status inv-issued  dep-div    sopo-amt      so-sopo-amt";
    grid-row-gap: 1px;
    grid-column-gap: 10px;
}
/*
        "rownum so    so-cust so-status acpt-date   dep-date so-amt  none"
        "rownum op    so-emp  so-status inv-issued  dep-div  sopo-amt so-sopo-amt";
    */

.so-r-list tr th,
.so-r-list tr td {
    display: block;
    border-style: none;
}

.so-r-list .rownum {
    grid-area: rownum;
    align-self: center;
    align-items: center;
    padding: 0;
}
.so-r-list .op {
    grid-area: op;
    white-space: normal;
}
.so-r-list .op .value {
    font-size: 0.75rem;
}
.so-r-list .so {
    grid-area: so;
    white-space: normal;
}
.so-r-list .so-cust {
    grid-area: so-cust;
    white-space: normal;
}
.so-r-list .so-status {
    grid-area: so-status;
}
.so-r-list .so-emp {
    grid-area: so-emp;
    white-space: normal;
}
.so-r-list .acpt-date {
    grid-area: acpt-date;
}
.so-r-list .inv-issued {
    grid-area: inv-issued;
}
.so-r-list .dep-date {
    grid-area: dep-date;
}
.so-r-list .dep-div {
    grid-area: dep-div;
}
.so-r-list .so-amt {
    grid-area: so-amt;
}
.so-r-list .sopo-amt {
    grid-area: sopo-amt;
}
.so-r-list .so-sopo-amt {
    grid-area: so-sopo-amt;
}
.so-r-list .sls-depts {
    grid-area: sls-depts;
}
.so-r-list .sls-rec-dates {
    grid-area: sls-rec-dates;
}
.so-r-list .op-dept {
    grid-area: op-dept;
}
.so-r-list .so-status {
    grid-area: so-status;
    white-space: normal;
}
.so-r-list .po-status {
    grid-area: po-status;
    white-space: normal;
}
.so-r-list .so-sls {
    grid-area: so-sls;
    text-align: right;
}

.strans-enter-active,
.strans-leave-active {
    /* animation: sizeScale 0.5s ease reverse; */
    transition: 0.25s;
}

.strans-enter,
.strans-leave-to {
    /* animation: sizeScale ease 0.5s; */
    opacity: 0;
}

.title .id::before {
    content: " : ";
}
.title .code::before {
    content: " : ";
}
.title .soid {
    font-size: 0.75rem;
}

.rownum > .actionButton {
    margin: 5px;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    padding: 5px 0 0 0;
    text-align: center;
    color: var(--text-weak-color);
}
.paildelimit {
    color: var(--text-weak-color);
    display: inline-block;
    padding: 0 1em;
}
.sls-mark {
    display: inline-block;
    padding: 5px;
    border-radius: 4px;
    border: 1px solid #ccc;
    margin-left: 3px;
    font-size: 0.85em;
}
.sls-mark.warn {
    background-color: red;
    color: white;
}
.sls-mark.info {
    border-color: transparent;
}
.sls-mark.notice {
    background-color: #666;
    color: white;
}
</style>
