<template>
    <div class="opportunity-retrieved">
        <div style="position:relative;">
            <div class="loader" v-if="executing" key="executing">
                Now loading...
            </div>
            <transition name="strans" mode="out-in">
                <table
                    class="op-r-list"
                    v-if="!executing && payload_data.length > 0"
                >
                    <tbody
                        v-for="(data, ix) in payload_data"
                        :key="data.opportunity_id"
                    >
                        <tr>
                            <th class="rownum">
                                <button
                                    class="actionButton"
                                    @click="showView(data)"
                                >
                                    {{ ix + 1 + listStatus.offset }}
                                </button>
                            </th>
                            <td data-label="案件ID" class="op">
                                <div class="title">
                                    <span class="opid">{{
                                        data.opportunity_id
                                    }}</span>
                                </div>
                                <div class="value">
                                    {{ data.opportunity_subject }}
                                </div>
                            </td>
                            <td data-label="受注先" class="so-cust">
                                <div class="title">
                                    受注先
                                    <span class="code">{{
                                        data.salesorder_customer_code
                                    }}</span>
                                </div>
                                <div class="value">
                                    {{ data.salesorder_customer_name_short }}
                                    <small>{{
                                        data.salesorder_customer_name_long
                                    }}</small>
                                </div>
                            </td>
                            <td data-label="ユーザ" class="user-cust">
                                <div class="title">
                                    ユーザ
                                    <span class="code">{{
                                        data.user_customer_code
                                    }}</span>
                                </div>
                                <div class="value">
                                    {{ data.user_customer_name_short }}
                                    <small>{{
                                        data.user_customer_name_long
                                    }}</small>
                                </div>
                            </td>
                            <td data-label="担当部署" class="op-dept">
                                <div class="title">
                                    担当部署
                                </div>
                                <div class="value">
                                    {{ data.opportunity_department_name_short }}
                                </div>
                            </td>
                            <td data-label="商流" class="op1-div">
                                <div class="title">
                                    商流
                                </div>
                                <div class="value">
                                    {{ viewDiv("op1", data.opportunity_div1) }}
                                </div>
                            </td>
                            <td data-label="経路" class="op2-div">
                                <div class="title">
                                    経路
                                </div>
                                <div class="value">
                                    {{ viewDiv("op2", data.opportunity_div2) }}
                                </div>
                            </td>
                            <td data-label="科目" class="op3-div">
                                <div class="title">
                                    科目
                                </div>
                                <div class="value">
                                    {{ viewDiv("op3", data.opportunity_div3) }}
                                </div>
                            </td>
                            <td data-label="受注ステータス" class="so-status">
                                <div class="title">
                                    受注ステータス
                                </div>
                                <div class="value">
                                    <span
                                        v-for="(v, c) in viewStsDiv(
                                            'sosts',
                                            data.opportunity_salesorder_status
                                        )"
                                        :key="c"
                                    >
                                        <span class="statusValue"
                                            >{{ c }}
                                            <div class="statusName">
                                                {{ v }}
                                            </div>
                                        </span>
                                    </span>
                                    <span
                                        class="warn"
                                        v-if="
                                            data.opportunity_salesorder_status ==
                                                null
                                        "
                                    >
                                        受注未登録
                                    </span>
                                </div>
                            </td>
                            <td data-label="発注ステータス" class="po-status">
                                <div class="title">
                                    発注ステータス
                                </div>
                                <div class="value">
                                    <span
                                        v-for="(v, c) in viewStsDiv(
                                            'posts',
                                            data.opportunity_purchaseorder_status
                                        )"
                                        :key="c"
                                    >
                                        <span class="statusValue"
                                            >{{ c }}
                                            <div class="statusName">
                                                {{ v }}
                                            </div>
                                        </span>
                                    </span>
                                </div>
                            </td>
                            <td data-label="最新検収日" class="acpt-date">
                                <div class="title">
                                    最新検収日
                                </div>
                                <div class="value">
                                    {{
                                        data.opportunity_acceptance_date | date
                                    }}
                                </div>
                            </td>
                            <td data-label="受注金額" class="so-amt amount">
                                <div class="title">
                                    受注金額
                                </div>
                                <div class="value">
                                    {{
                                        data.opportunity_salesorder_amount
                                            | amount
                                    }}
                                </div>
                            </td>
                            <td data-label="発注金額" class="po-amt amount">
                                <div class="title">
                                    発注金額
                                </div>
                                <div class="value">
                                    {{
                                        data.opportunity_purchaseorder_amount
                                            | amount
                                    }}
                                </div>
                            </td>
                            <td data-label="内部売上" class="sopo-amt amount">
                                <div class="title">
                                    内部売上
                                </div>
                                <div class="value">
                                    {{
                                        (data.opportunity_salesorder_amount -
                                            data.opportunity_purchaseorder_amount)
                                            | amount
                                    }}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div v-if="inited && !executing && payload_data.length < 1">
                    該当するデータが見つかりません。
                </div>
            </transition>
        </div>
        <MopSortSelectorModal
            v-if="listStatus.showSortModal"
            title="並べ替え"
            :columns="{
                opid: '案件ID',
                opdtcd: '担当部署コード',
                soctcd: '受注先コード',
                uctcd: 'ユーザコード',
                opd1: '商流コード',
                opd2: '経路コード',
                opd3: '科目コード',
                sosts: '受注ステータス',
                acdt: '検収日',
                soamt: '受注金額',
                poamt: '発注金額',
                remamt: '内部売上'
            }"
            :orders="query.opOrders"
            id="oppolistsort"
            @cancel="listStatus.showSortModal = false"
            @decide="sortList"
        />
    </div>
</template>

<script>
import MopSortSelectorModal from "@/components/MopSortSelectorModal.vue";

export default {
    name: "opportunity-list",
    components: {
        MopSortSelectorModal
    },
    model: {
        prop: "query"
    },
    props: {
        query: Object,
        makeParams: Function,
        listStatus: Object
    },
    data: () => ({
        payload_data: [],
        executing: false,
        inited: false
    }),
    watch: {
        query: function() {
            this.execute();
        }
    },
    methods: {
        showView: function(data) {
            this.routePush({
                name: "Opportunity",
                params: { opid: data.opportunity_id }
            });
        },
        viewDiv: function(divkey, code) {
            let ret = code;
            let div = this.divs[divkey][code];
            if (div != null) {
                ret = div.contentshort;
            }
            return ret;
        },
        viewStsDiv: function(divkey, code) {
            let ret = {};
            if (code) {
                let len = code.length;
                const divdict = this.divs[divkey];
                for (let i = 0; i < len; i++) {
                    let c = code.charAt(i);
                    const v = divdict[c];
                    if (v) {
                        ret[c] = v.contentshort;
                    } else {
                        ret[c] = c;
                    }
                }
            }
            return ret;
        },
        execute: function(scy) {
            // this.payload_data = [];

            let params = this.makeParams(this.query.opOrders);
            if (params) {
                this.executing = true;
                this.readDataToArray(
                    this.makeurl("op", params),
                    (list, hd, count, limit, offset) => {
                        this.payload_data = list;
                        this.listStatus.count = count;
                        this.listStatus.limit = limit;
                        this.listStatus.offset = offset;
                    },
                    null,
                    null,
                    "oppotunitylist"
                ).finally(() => {
                    this.executing = false;
                    if (scy) {
                        setTimeout(() => {
                            scrollTo(0, scy);
                        }, 500);
                    }
                });
            }
        },
        sortList: function(orders) {
            this.listStatus.showSortModal = false;
            this.query.opOrders.length = 0;
            this.query.opOrders.push(...orders);
            this.execute();
        }
    },
    created: async function() {
        this.inited = false;
        await this.loaddivs("op1", "op2", "op3", "sosts", "posts");
        await this.execute(this.$route.meta.scrollY);
        this.$route.meta.scrollY = 0;
        this.inited = true;
    }
};
</script>

<style scoped>
.opportunity-retrieved > .header {
    position: sticky;
    top: 0;
}
.opportunity-retrieved > .header h1 {
    display: inline-block;
}
.opportunity-retrieved > .header .sortbutton {
    float: right;
}

thead {
    display: none;
}
td {
    vertical-align: top;
    border-right-style: none;
    border-left-style: none;
}
td .title {
    font-size: 0.5em;
    display: block;
}
td .value {
    padding: 3px 0;
}
td .value > small {
    font-size: x-small;
    display: inline-block;
    color: var(--text-weak-color);
}
td .value .id {
    font-size: 0.75em;
}
td .value .id:after {
    content: " : ";
}
tbody:nth-child(2n + 1) {
    background: var(--odd-bg-color);
}

/* --- */
.op-r-list {
    position: relative;
    width: calc(100% - 20px);
}
.op-r-list tbody {
    display: block;
    border-bottom: 1px solid var(--border-color);
}

.op-r-list tbody tr {
    display: grid;
    width: 100%;
    /* grid-template-rows: 60px 60px; */
    grid-template-columns: 50px 10px 3fr 2fr 80px 80px 100px 90px 120px 120px;
    grid-template-areas:
        "rownum   op       op         so-cust      op1-div op3-div  so-status acpt-date  so-amt  empty1"
        "rownum   empty2   op-dept    user-cust    op2-div op4-div  po-status acpt-date   po-amt  sopo-amt";
    grid-row-gap: 10px;
    grid-column-gap: 10px;
    padding: 10px 5px;
}
.op-r-list tr th,
.op-r-list tr td {
    display: block;
    border-style: none;
    padding: 0;
}

.op-r-list .rownum {
    grid-area: rownum;
    align-self: center;
    padding: 0;
}
.op-r-list .op {
    grid-area: op;
    white-space: normal;
}
.op-r-list .so-cust {
    grid-area: so-cust;
    white-space: normal;
}
.op-r-list .user-cust {
    grid-area: user-cust;
    white-space: normal;
}
.op-r-list .op-dept {
    grid-area: op-dept;
}
.op-r-list .op1-div {
    grid-area: op1-div;
}
.op-r-list .op2-div {
    grid-area: op2-div;
}
.op-r-list .op3-div {
    grid-area: op3-div;
}
.op-r-list .so-status {
    grid-area: so-status;
    white-space: normal;
}
.op-r-list .po-status {
    grid-area: po-status;
    white-space: normal;
}
.op-r-list .acpt-date {
    grid-area: acpt-date;
}
.op-r-list .so-amt {
    grid-area: so-amt;
}
.op-r-list .po-amt {
    grid-area: po-amt;
}
.op-r-list .sopo-amt {
    grid-area: sopo-amt;
}

.statusValue {
    font-size: 1rem;
}

.strans-enter-active,
.strans-leave-active {
    /* animation: sizeScale 0.5s ease reverse; */
    transition: 0.25s;
}

.strans-enter,
.strans-leave-to {
    /* animation: sizeScale ease 0.5s; */
    opacity: 0;
}

.title .id::before {
    content: " : ";
}
.title .code::before {
    content: " : ";
}
.title .opid {
    font-size: 0.75rem;
}

.rownum > .actionButton {
    margin: 5px;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    padding: 5px 0 0 0;
    text-align: center;
    color: var(--text-weak-color);
}
.warn {
    color: red;
}
</style>
