<template>
    <div class="purchaseorder-retrieved">
        <div style="position:relative;">
            <div class="loader" v-if="executing" key="executing">
                Now loading...
            </div>
            <transition name="strans" mode="out-in">
                <table
                    class="po-r-list"
                    v-if="!executing && payload_data.length > 0"
                >
                    <tbody
                        v-for="(d, ix) in payload_data"
                        :key="d.purchaseorder_id"
                    >
                        <tr>
                            <th class="rownum">
                                <button
                                    class="actionButton"
                                    @click="showView(d)"
                                >
                                    {{ ix + 1 + listStatus.offset }}
                                </button>
                            </th>
                            <td data-label="案件" class="op">
                                <div class="title">
                                    案件<span class="id">{{
                                        d.opportunity_id
                                    }}</span>
                                </div>
                                <div class="value">
                                    {{ d.opportunity_subject }}
                                </div>
                            </td>
                            <td data-label="担当部署" class="op-dept">
                                <div class="title">
                                    担当部署
                                </div>
                                <div class="value">
                                    {{ deptName(d.opportunity_department_id) }}
                                </div>
                            </td>
                            <td data-label="受注先" class="so-cust">
                                <div class="title">
                                    受注先
                                    <span class="code">{{
                                        d.salesorder_customer_code
                                    }}</span>
                                </div>
                                <div class="value">
                                    {{ d.salesorder_customer_name_short }}
                                    <small>{{
                                        d.salesorder_customer_name_long
                                    }}</small>
                                </div>
                            </td>
                            <td data-label="ユーザ" class="so-user">
                                <div class="title">
                                    ユーザ
                                    <span class="code">{{
                                        d.user_customer_code
                                    }}</span>
                                </div>
                                <div class="value">
                                    {{ d.user_customer_name_short }}
                                    <small>{{
                                        d.user_customer_name_long
                                    }}</small>
                                </div>
                            </td>
                            <td data-label="検収日" class="acpt-date">
                                <div class="title">
                                    検収日
                                </div>
                                <div class="value">
                                    {{ d.acceptance_date | date }}
                                </div>
                            </td>
                            <td data-label="発注" class="po">
                                <div class="title">
                                    <span class="poid">{{
                                        d.purchaseorder_id
                                    }}</span>
                                </div>
                                <div class="value">
                                    {{ d.purchaseorder_subject }}
                                </div>
                            </td>
                            <td data-label="発注先" class="po-cust">
                                <div class="title">
                                    発注先<span class="id">{{
                                        d.purchaseorder_customer_code
                                    }}</span>
                                </div>
                                <div class="value">
                                    {{ d.purchaseorder_customer_name_short }}
                                    <small>{{
                                        d.purchaseorder_customer_name_long
                                    }}</small>
                                </div>
                            </td>
                            <td data-label="ステータス" class="po-status">
                                <div class="title">
                                    ステータス
                                </div>
                                <div class="value">
                                    <span
                                        style="padding:0 5px; display:inline-block; text-align:center;"
                                        >{{ d.purchaseorder_status }}
                                        <div style="font-size:0.5em;">
                                            {{
                                                divName(
                                                    "posts",
                                                    d.purchaseorder_status
                                                )
                                            }}
                                        </div>
                                    </span>
                                </div>
                            </td>
                            <td class="po-div">
                                <div class="title">区分</div>
                                <!-- 発注区分 -->
                                <div class="value">
                                    {{ viewDiv("po", d.purchaseorder_div) }}
                                </div>
                            </td>
                            <!-- <td data-label="発注担当者" class="po-emp">
                                <div class="title">
                                    発注担当者<span class="id">{{
                                        d.purchaseorder_employee_id
                                    }}</span>
                                </div>
                                <div class="value">
                                    {{ d.purchaseorder_employee_name_long }}
                                </div>
                            </td> -->
                            <td data-label="納品日" class="dlv-date">
                                <div class="title">納品日</div>
                                <div class="value">
                                    {{ d.delivery_date | date }}
                                </div>
                            </td>

                            <td data-label="請求書受領" class="inv-rcpt">
                                <div class="title">請求書受領</div>
                                <div class="value">
                                    {{
                                        viewDiv(
                                            "invrcpt",
                                            d.invoice_receipt_div
                                        )
                                    }}
                                </div>
                            </td>
                            <td data-label="支払日" class="pmt-date">
                                <div class="title">支払日</div>
                                <div class="value">
                                    {{ d.payment_date | date }}
                                </div>
                            </td>
                            <td data-label="支払" class="pmt-div">
                                <div class="title">支払</div>
                                <div class="value">
                                    {{ viewDiv("pmt", d.payment_div) }}
                                </div>
                            </td>
                            <td data-label="発注金額" class="po-amt amount">
                                <div class="title">発注金額</div>
                                <div class="value">
                                    {{ d.purchaseorder_amount | amount }}
                                </div>
                            </td>
                            <td data-label="原価" class="po-cos">
                                <div class="value">
                                    <div
                                        v-if="d.cos_status == -1"
                                        class="cos-mark warn"
                                    >
                                        ≠ 原価金額
                                    </div>
                                    <div
                                        v-else-if="d.cos_status > 1"
                                        class="cos-mark notice"
                                    >
                                        原価 {{ d.cos_status }} 件
                                    </div>
                                    <div
                                        v-else-if="d.cos_status == -2"
                                        class="cos-mark notice"
                                    >
                                        ≠ 原価部署
                                    </div>
                                    <div
                                        v-else-if="d.cos_status == -3"
                                        class="cos-mark notice"
                                    >
                                        ≠ 原価計上日
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div v-if="inited && !executing && payload_data.length < 1">
                    該当するデータが見つかりません。
                </div>
            </transition>
        </div>

        <MopSortSelectorModal
            v-if="listStatus.showSortModal"
            title="並べ替え"
            :columns="{
                poid: '発注ID',
                opid: '案件ID',
                poctcd: '発注先コード',
                poempid: '発注担当者ID',
                posts: '発注ステータス',
                dlvdt: '納品日',
                invrdv: '請求書受領区分',
                pmtdt: '支払日',
                pmtdv: '支払区分',
                poamt: '発注金額'
            }"
            :orders="query.poOrders"
            @cancel="listStatus.showSortModal = false"
            @decide="sortList"
        />
    </div>
</template>

<script>
import MopSortSelectorModal from "@/components/MopSortSelectorModal.vue";
import DateUtils from "@/DateUtils";

export default {
    name: "PurchaseorderRetrived",
    components: {
        MopSortSelectorModal
    },
    model: {
        prop: "query"
    },
    props: {
        query: Object,
        makeParams: Function,
        listStatus: Object
    },
    data: () => ({
        payload_data: [],
        executing: false,
        inited: false,
        wait_exec_timeid: 0
    }),
    watch: {
        query: function() {
            this.reserveExecute();
        }
    },
    methods: {
        openPost: function() {
            this.editDialog.show = true;
        },
        closePost: function() {
            this.editDialog.show = false;
        },
        showView: function(data) {
            this.routePush({
                name: "Opportunity",
                params: {
                    opid: data.opportunity_id,
                    soid: data.salesorder_id,
                    poid: data.purchaseorder_id
                }
            });
        },
        viewDiv: function(divkey, code) {
            let ret = code;
            let div = this.divs[divkey][code];
            if (div != null) {
                ret = div.contentshort;
            }
            return ret;
        },
        divName: function(divkey, code) {
            let ret = "";
            let div = this.divs[divkey][code];
            if (div != null) {
                ret = div.contentshort;
            }
            return ret;
        },
        deptName: function(id) {
            let ret = "";
            let dept = this.department_all_for_list[id];
            if (dept != null) {
                ret = dept.department_name_short;
            }
            return ret;
        },

        reserveExecute: function() {
            if (this.wait_exec_timeid > 0) {
                clearTimeout(this.wait_exec_timeid);
                this.wait_exec_timeid = 0;
            }

            let sec = 500;
            if (this.executing) {
                sec = 1000;
            }

            this.wait_exec_timeid = setTimeout(() => {
                this.wait_exec_timeid = 0;
                this.execute();
            }, sec);
        },

        execute: function(scy) {
            // this.payload_data = [];
            this.executing = true;
            this.readDataToArray(
                this.makeurl("po", this.makeParams(this.query.poOrders)),
                (list, hd, count, limit, offset) => {
                    this.payload_data = list;
                    this.listStatus.count = count;
                    this.listStatus.limit = limit;
                    this.listStatus.offset = offset;
                    for (let item of this.payload_data) {
                        item["cos_status"] = this.howCos(item);
                    }
                },
                null,
                null,
                "oppotunitylist"
            ).finally(() => {
                this.executing = false;
                if (scy) {
                    setTimeout(() => {
                        scrollTo(0, scy);
                    }, 500);
                }
            });
        },
        sortList: function(orders) {
            this.listStatus.showSortModal = false;
            this.query.poOrders.length = 0;
            this.query.poOrders.push(...orders);
            this.reserveExecute();
        },
        howCos: function(d) {
            let ret = 0;
            if (d.cos_amt != d.purchaseorder_amount) {
                ret = -1;
            } else if (d.cos_count > 1) {
                ret = d.cos_count;
            } else if (d.opportunity_department_id != d.cos_depts) {
                ret = -2;
            } else if (
                d.cos_rec_dates != DateUtils.toString8(d.delivery_date)
            ) {
                ret -= 3;
            }

            return ret;
        }
    },
    created: async function() {
        this.inited = false;
        await this.loaddivs("posts", "invrcpt", "pmt", "po");
        await this.loadDepartmentAllForList();
        await this.execute(this.$route.meta.scrollY);
        this.$route.meta.scrollY = 0;
        this.inited = true;
    }
};
</script>

<style scoped>
.purchaseorder-retrieved > .header h1 {
    display: inline-block;
}
.purchaseorder-retrieved > .header .sortbutton {
    float: right;
}

thead {
    display: none;
}
td {
    vertical-align: top;
    border-right-style: none;
    border-left-style: none;
}
td .title {
    font-size: 0.5em;
    display: block;
}
td .value {
    padding: 3px 0;
}
td .value > small {
    font-size: x-small;
    display: inline-block;
    color: var(--text-weak-color);
}
td .value .id {
    font-size: 0.75em;
    display: inline-block;
}
td .value .id:after {
    content: " : ";
}
tbody:nth-child(2n + 1) {
    background: var(--odd-bg-color);
}

/* --- */
.po-r-list {
    position: relative;
    width: calc(100% - 20px);
}
.po-r-list tbody {
    display: block;
    border-bottom: 1px solid var(--border-color);
}

.po-r-list tbody tr {
    display: grid;
    /* grid-template-rows: 60px 60px; */
    grid-template-columns: 50px 3fr 80px 2fr 2fr 60px 100px 100px 120px;
    grid-template-areas:
        "rownum   po  po      so-cust  po-cust     po-status  dlv-date  pmt-date  po-cos"
        "rownum   op  op-dept so-user  acpt-date   po-div  inv-rcpt  pmt-div   po-amt";
    grid-row-gap: 1px;
    grid-column-gap: 10px;
}
.po-r-list tr th,
.po-r-list tr td {
    display: block;
    border-style: none;
}

.po-r-list .rownum {
    grid-area: rownum;
    align-self: center;
    padding: 0;
}
.po-r-list .op {
    grid-area: op;
    white-space: normal;
}
.po-r-list .op .value {
    font-size: 0.75rem;
}
.po-r-list .op-dept {
    grid-area: op-dept;
}
.po-r-list .so-cust {
    grid-area: so-cust;
    white-space: normal;
}
.po-r-list .so-user {
    grid-area: so-user;
    white-space: normal;
}
.po-r-list .acpt-date {
    grid-area: acpt-date;
}
.po-r-list .po {
    grid-area: po;
    white-space: normal;
}
.po-r-list .po-status {
    grid-area: po-status;
}
.po-r-list .po-div {
    grid-area: po-div;
}
.po-r-list .po-cust {
    grid-area: po-cust;
    white-space: normal;
}
.po-r-list .po-emp {
    grid-area: po-emp;
    white-space: normal;
}
.po-r-list .dlv-date {
    grid-area: dlv-date;
}
.po-r-list .inv-rcpt {
    grid-area: inv-rcpt;
}
.po-r-list .pmt-date {
    grid-area: pmt-date;
}
.po-r-list .pmt-div {
    grid-area: pmt-div;
}
.po-r-list .po-amt {
    grid-area: po-amt;
}
.po-r-list .po-cos {
    grid-area: po-cos;
    text-align: right;
}

.strans-enter-active,
.strans-leave-active {
    /* animation: sizeScale 0.5s ease reverse; */
    transition: 0.25s;
}

.strans-enter,
.strans-leave-to {
    /* animation: sizeScale ease 0.5s; */
    opacity: 0;
}

.title .id::before {
    content: " : ";
}
.title .code::before {
    content: " : ";
}
.title .poid {
    font-size: 0.75rem;
}

.rownum > .actionButton {
    margin: 5px;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    padding: 5px 0 0 0;
    text-align: center;
    color: var(--text-weak-color);
}
.paildelimit {
    color: var(--text-weak-color);
    display: inline-block;
    padding: 0 1em;
}
.cos-mark {
    display: inline-block;
    padding: 5px;
    border-radius: 4px;
    border: 1px solid #ccc;
    margin-left: 3px;
    font-size: 0.85em;
}
.cos-mark.warn {
    background-color: red;
    color: white;
}
.cos-mark.info {
    border-color: transparent;
}
.cos-mark.notice {
    background-color: #666;
    color: white;
}
</style>
