<template>
    <div class="op-edit-panel">
        <div class="form" v-if="isStep(0)">
            <div class="op-form">
                <div class="item op-subject">
                    <OpTextField
                        label="案件名"
                        v-model.trim="d.opportunity_subject"
                        required
                        maxlength="80"
                        name="opportunity_subject"
                        @changed-valid="changedValid"
                        ref="opportunitySubject"
                    />
                </div>
                <div class="item so-customer">
                    <OpSelectCustomer
                        label="受注先"
                        v-model="salesorder_customer"
                        required
                        name="salesorder_customer"
                        @changed-valid="changedValid"
                    />
                    <button
                        tabindex="-1"
                        class="addButton"
                        @click="editCustomer('so')"
                    >
                        +
                    </button>
                </div>
                <div class="item user-customer">
                    <OpSelectCustomer
                        label="ユーザ取引先"
                        v-model="user_customer"
                        required
                        name="user_customer"
                        @changed-valid="changedValid"
                    />
                    <button
                        tabindex="-1"
                        class="addButton"
                        @click="editCustomer('user')"
                    >
                        +
                    </button>
                </div>
                <div class="item op-department">
                    <OpSelectDepartment
                        label="案件担当部署"
                        v-model="opportunity_department"
                        required
                        name="opportunity_department"
                        @changed-valid="changedValid"
                        div="1"
                    />
                </div>
                <div class="item op-div1">
                    <OpDivSelector
                        label="商流"
                        v-model="d.opportunity_div1"
                        :divlist="divs['op1']"
                        name="op1"
                        @changed-valid="changedValid"
                    />
                </div>
                <div class="item op-div2">
                    <OpDivSelector
                        label="経路"
                        v-model="d.opportunity_div2"
                        :divlist="divs['op2']"
                        name="op2"
                        @changed-valid="changedValid"
                    />
                </div>
                <div class="item op-div3">
                    <OpDivSelector
                        label="科目"
                        v-model="d.opportunity_div3"
                        :divlist="divs['op3']"
                        name="op3"
                        @changed-valid="changedValid"
                    />
                </div>
                <!-- <div class="item op-div4">
                    <OpDivSelector
                        label="案件区分4"
                        v-model="d.opportunity_div4"
                        :divlist="divs['op4']"
                        name="op4"
                        @changed-valid="changedValid"
                    />
                </div>
                <div class="item op-div5">
                    <OpDivSelector
                        label="案件区分5"
                        v-model="d.opportunity_div5"
                        :divlist="divs['op5']"
                        name="op5"
                        @changed-valid="changedValid"
                    />
                </div> -->
                <div class="item business-note">
                    <OpTextField
                        label="商談備考"
                        v-model.trim="d.business_note"
                        maxlength="40"
                        name="business_note"
                        @changed-valid="changedValid"
                    />
                </div>
                <div class="item op-note">
                    <OpTextField
                        label="案件備考"
                        v-model.trim="d.opportunity_note"
                        type="textarea"
                        maxlength="4000"
                        name="oppurtunity_note"
                        @changed-valid="changedValid"
                    />
                    <div class="op-note-appender">
                        <OpTextField
                            v-model="opnoteAppendValue"
                            type="textarea"
                            maxlength="200"
                            maxwidth="calc(100% - 50px)"
                            name="oppurtunity_note_appender"
                            @keydown.enter.ctrl.exact.stop.prevent="
                                appendOpNote()
                            "
                            note="⇖を押すとタイムスタンプを付加して案件備考の最後に追記します。"
                        />
                        <button @click="appendOpNote()">⇖</button>
                    </div>
                </div>
            </div>
            <div class="control-box">
                <div>
                    <button @click="doCancel">キャンセル</button>
                </div>
                <div>
                    <button @click="doConfirm" v-bind:disabled="hasErrors">
                        確認
                    </button>
                </div>
                <div></div>
            </div>
        </div>
        <div v-if="isStep(1)">
            <table class="confirm">
                <tr>
                    <th>案件名</th>
                    <td>{{ d.opportunity_subject }}</td>
                </tr>
                <tr>
                    <th>受注先</th>
                    <td>
                        <span class="id">{{
                            salesorder_customer.customer_id
                        }}</span>
                        <span class="value">{{
                            salesorder_customer.customer_name_long
                        }}</span>
                    </td>
                </tr>
                <tr>
                    <th>ユーザ取引先</th>
                    <td>
                        <span class="id">{{ user_customer.customer_id }}</span>
                        <span class="value">{{
                            user_customer.customer_name_long
                        }}</span>
                    </td>
                </tr>

                <tr>
                    <th>案件担当部署</th>
                    <td>
                        <span class="id">{{
                            opportunity_department.department_id
                        }}</span>
                        <span class="value">{{
                            opportunity_department.department_name_long
                        }}</span>
                    </td>
                </tr>
                <tr>
                    <th>商流</th>
                    <td>
                        <span class="code">{{ d.opportunity_div1 }}</span>
                        <span class="value">{{
                            divname("op1", d.opportunity_div1)
                        }}</span>
                    </td>
                </tr>
                <tr>
                    <th>経路</th>
                    <td>
                        <span class="code">{{ d.opportunity_div2 }}</span>
                        <span class="value">{{
                            divname("op2", d.opportunity_div2)
                        }}</span>
                    </td>
                </tr>
                <tr>
                    <th>科目</th>
                    <td>
                        <span class="code">{{ d.opportunity_div3 }}</span>
                        <span class="value">{{
                            divname("op3", d.opportunity_div3)
                        }}</span>
                    </td>
                </tr>
                <!-- <tr>
                    <th>案件区分4</th>
                    <td>
                        <span class="code">{{ d.opportunity_div4 }}</span>
                        <span class="value">{{
                            divname("op4", d.opportunity_div4)
                        }}</span>
                    </td>
                </tr>
                <tr>
                    <th>案件区分5</th>
                    <td>
                        <span class="code">{{ d.opportunity_div5 }}</span>
                        <span class="value">{{
                            divname("op5", d.opportunity_div5)
                        }}</span>
                    </td>
                </tr> -->
                <tr>
                    <th>商談備考</th>
                    <td>{{ d.business_note }}</td>
                </tr>
                <tr>
                    <th>案件備考</th>
                    <td>
                        <!-- {{ d.opportunity_note }} -->
                        <div class="opnote-frame" ref="opnoteFrame">
                            <div
                                v-for="(line, ix) in getParsedOpNote()"
                                :key="ix"
                            >
                                <div class="opnote-line">
                                    {{ line.message }}
                                    <span
                                        v-if="line.stamp"
                                        class="opnote-line-stamp"
                                    >
                                        {{ line.stamp }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
            </table>

            <div class="control-box">
                <div>
                    <button @click="setStep(0)">
                        戻る
                    </button>
                </div>
                <div>
                    <button @click="updateData" v-if="mode == 'update'">
                        登録
                    </button>
                    <button @click="addData" v-else>
                        追加
                    </button>
                </div>
                <div></div>
            </div>
        </div>
        <div v-if="isStep(2)" class="complete">
            <div class="message" v-if="mode == 'create'">
                案件 (ID : {{ d.opportunity_id }}) を登録しました。
            </div>
            <div class="message" v-if="mode == 'update'">
                案件 (ID : {{ d.opportunity_id }}) の情報を修正しました。
            </div>

            <div class="control-box">
                <div></div>
                <div>
                    <button @click="close">
                        閉じる
                    </button>
                </div>
                <div>
                    <button v-if="mode != 'update'" @click="moveDetail">
                        詳細
                    </button>
                </div>
            </div>
        </div>
        <OpModal
            title="取引先登録"
            v-if="showCustomerEditDialog"
            @close="showCustomerEditDialog = false"
            v-slot="{ close, step }"
            :steps="['入力', '確認', '完了']"
        >
            <CustomerEditor
                name="customerEditor"
                mode="create"
                hiddenViewButton
                @fin="close"
                @step="step"
                @updated="updatedCustomer"
            />
        </OpModal>
    </div>
</template>

<script>
import OpSelectCustomer from "@/components/OpSelectCustomer.vue";
import OpSelectDepartment from "@/components/OpSelectDepartment.vue";
import EditorMixin from "@/views/EditorMixin.vue";
import CustomerEditor from "@/views/customer/CustomerEditor.vue";
import DateUtils from "@/DateUtils";

// const axios = require("axios");

export default {
    name: "OpportunityEditor",
    mixins: [EditorMixin],
    model: {
        prop: "model"
    },
    props: {
        model: Object,
        oppo: Object,
        mode: String
    },
    components: {
        OpSelectCustomer,
        OpSelectDepartment,
        CustomerEditor
    },
    data: function() {
        return {
            d: { ...this.model },
            opportunity_department: this.copyPartIdCodeName(
                this.model,
                "opportunity_",
                "department_"
            ),
            salesorder_customer: this.copyPartIdCodeName(
                this.model,
                "salesorder_",
                "customer_"
            ),
            user_customer: this.copyPartIdCodeName(
                this.model,
                "user_",
                "customer_"
            ),
            opnoteAppendValue: "",
            showCustomerEditDialog: false,
            targetCustomerType: null
        };
    },
    watch: {
        opportunity_department: function(val) {
            if (val) {
                this.d.opportunity_department_id = val.department_id;
            } else {
                this.d.opportunity_department_id = null;
            }
        },
        salesorder_customer: function(val) {
            let v = null;
            if (val) {
                v = val.customer_id;
            }
            this.d.salesorder_customer_id = v;
        },
        user_customer: function(val) {
            let v = null;
            if (val) {
                v = val.customer_id;
            }
            this.d.user_customer_id = v;
        }
    },
    computed: {
        remining: function() {
            return this.d.customer_code.length + "/" + 4;
        }
    },
    methods: {
        doConfirm: function() {
            this.setStep(1);
        },
        addData: function() {
            this.postData(this.makeurl("op"), this.d, dt => {
                this.d = dt;
                this.$emit("updated", dt);
                this.setStep(2);
            });
        },
        updateData: function() {
            this.putData(
                this.makeurl("op", this.d.opportunity_id),
                this.d,
                dt => {
                    this.d = dt;
                    this.$emit("updated", dt);
                    this.setStep(2);
                }
            );
        },
        editCustomer: function(type) {
            this.targetCustomerType = type;
            this.showCustomerEditDialog = true;
        },
        updatedCustomer: function(val) {
            if (this.targetCustomerType == "so") {
                this.salesorder_customer = val;
            } else if (this.targetCustomerType == "user") {
                this.user_customer = val;
            }
            // TODO reset cache
            this.$store.dispatch("clearCustomerList");
        },
        moveDetail: function() {
            this.$emit("moveto", this.d);
            this.close();
        },

        divname: function(divkey, code) {
            let ret = "";
            let div = this.divs[divkey][code];
            if (div != null) {
                ret = div.contentlong;
            }
            return ret;
        },
        appendOpNote: function() {
            let val = this.opnoteAppendValue.trimEnd();
            if (val) {
                this.d.opportunity_note +=
                    "\n" +
                    val +
                    "(" +
                    this.getAccountName() +
                    " " +
                    DateUtils.formatDatetime(new Date(), true) +
                    ")";
                this.opnoteAppendValue = "";
            }
        },
        getParsedOpNote: function() {
            let ret = [];
            let opn = this.d.opportunity_note;
            if (opn) {
                let lines = [];
                let opns = opn.split(/\n/);
                for (let s of opns) {
                    let msg = "";
                    let stm = "";
                    let ix = DateUtils.searchCommentTimestamp(s);
                    if (ix > 0) {
                        msg = s.slice(0, ix);
                        stm = s.slice(ix);
                    } else {
                        msg = s;
                    }
                    lines.push({ message: msg, stamp: stm });
                }
                ret = lines;
            }
            return ret;
        }
    },
    created: async function() {
        await this.loaddivs("op1", "op2", "op3", "op4", "op5");
        this.loaddeptlist();
        this.$refs.opportunitySubject.focus();
    }
};
</script>

<style scoped>
.op-edit-panel {
    min-width: 400px;
    position: relative;
}
.control-box {
    display: grid;
    /* border-top: 1px solid gray; */
    padding: 5px 0;
    grid-template-columns: 150px 1fr 150px;
    justify-items: center;
}
.control-box > div:first-child {
    justify-self: left;
}
.control-box > div:last-child {
    justify-self: right;
}

.complete .message {
    font-size: 1.2rem;
    white-space: nowrap;
    padding: 20px 10px;
    text-align: center;
}

.op-form {
    position: relative;
    display: grid;
    grid-template-columns: 450px 450px;
    grid-template-areas:
        "op-subject      op-subject"
        "so-customer      user-customer"
        "op-department    op-note"
        "op-div1 op-note"
        "op-div2 op-note"
        "op-div3 op-note"
        "op-div4 op-note"
        "op-div5  op-note"
        "business-note   op-note";
    grid-row-gap: 10px;
    grid-column-gap: 30px;
}

.op-form .form-field {
    width: 100%;
}

.op-form .cust-attr .item {
    display: flex;
}

.op-form .op-subject {
    grid-area: op-subject;
}
.op-form .op-subject > * {
    width: 100%;
}
.op-form .so-customer {
    grid-area: so-customer;
    display: grid;
    grid-template-columns: 1fr 60px;
    /* justify-items: center; */
}
.op-form .addButton {
    display: inline-block;
    margin-top: 1rem;
}

.op-form .user-customer {
    grid-area: user-customer;
    display: grid;
    grid-template-columns: 1fr 60px;
    /* justify-items: center; */
}
.op-form .op-department {
    grid-area: op-department;
}
.op-form .op-div1 {
    grid-area: op-div1;
}

.op-form .op-div2 {
    grid-area: op-div2;
}
.op-form .op-div3 {
    grid-area: op-div3;
}
.op-form .op-div4 {
    grid-area: op-div4;
}
.op-form .op-div5 {
    grid-area: op-div5;
}
.op-form .business-note {
    grid-area: business-note;
}
.op-form .op-note {
    grid-area: op-note;
}
</style>
<style>
.op-form .op-div1 .radio-group,
.op-form .op-div2 .radio-group,
.op-form .op-div3 .radio-group,
.op-form .op-div4 .radio-group,
.op-form .op-div5 .radio-group {
    /* display: flex;
    flex-direction: column; */
    white-space: pre-wrap;
}
.op-form .op-note textarea {
    height: 4em;
    padding: 10px 30px 10px 15px;
}

.op-form .form-field {
    /* width: calc(100% - 60px); */
    width: 100%;
}
.op-form .op-department .form-field {
    width: calc(100% - 60px);
}

.op-form .op-note textarea {
    height: 20rem;
}

.op-form .op-note .op-note-appender .op-textfield-inner {
    height: 5rem;
}
.op-form .op-note .op-note-appender > button {
    vertical-align: top;
    margin-top: 0.125rem;
    height: 5rem;
}

.opnote-frame {
    white-space: pre-wrap;
    width: 100%;
    height: 12rem;
    overflow-y: scroll;
    padding: 10px;
    /* box-shadow: var(--block-shadow);
    border: 1px solid #ccc;
    background-color: white; */
}

/* .opnote-frame > div:last-child {
    margin-bottom: 6rem;
} */
.opnote-line {
    clear: right;
    display: inline-block;
}
.opnote-line-stamp {
    font-size: 0.75rem;
    float: right;
    color: var(--text-weak-color);
}
</style>
