<template>
    <div class="op-retrieval-panel">
        <div class="op-retrieval-box">
            <OpportunityQuery v-model="query" @changed-query="changedQuery">
                <div class="list-status">
                    <div v-if="query.listdiv == 'op'">
                        案件
                        <MopAddButton @click="opEditor.show = true" />
                    </div>
                    <div v-if="query.listdiv == 'so'">
                        受注
                    </div>
                    <div v-if="query.listdiv == 'po'">
                        発注
                    </div>
                    <div>該当 {{ listStatus.count }} 件</div>
                    <div>
                        <div class="pagination-links">
                            <a
                                @click="changePage(1)"
                                class="pagination-ctrl pagination-first"
                            >
                                |&lt;</a
                            >

                            <a
                                @click="changePage(currentPage - 1)"
                                class="pagination-ctrl pagination-pre"
                            >
                                &lt;</a
                            >

                            <ul class="pagination-list" ref="paginationlist">
                                <li v-for="ix of pages" :key="ix">
                                    <span
                                        :id="'p' + ix"
                                        v-if="currentPage == ix"
                                        class="pagination-current"
                                    >
                                        {{ ix }}
                                    </span>
                                    <a
                                        :id="'p' + ix"
                                        v-else
                                        @click="changePage(ix)"
                                        class="pagination-link"
                                        >{{ ix }}</a
                                    >
                                </li>
                            </ul>
                            <a
                                @click="changePage(currentPage + 1)"
                                class="pagination-ctrl pagination-next"
                            >
                                &gt;</a
                            >
                            <a
                                @click="changePage(pages)"
                                class="pagination-ctrl pagination-end"
                            >
                                &gt;|</a
                            >
                        </div>
                    </div>
                    <div>
                        {{ currentPage }} / {{ pages }} (
                        {{ listStatus.limit }} <sup>件</sup>/<sub>頁</sub> )
                    </div>

                    <div>
                        <MopSortButton
                            class="sortbutton"
                            @click="listStatus.showSortModal = true"
                        />
                    </div>
                </div>
            </OpportunityQuery>
        </div>
        <div style="position: relative;">
            <OpportunityList
                v-model="query"
                :makeParams="makeQueryParams"
                v-if="query.listdiv == 'op'"
                :listStatus="listStatus"
            ></OpportunityList>
            <SalesorderRetrieved
                v-model="query"
                :makeParams="makeQueryParams"
                v-if="query.listdiv == 'so'"
                :listStatus="listStatus"
            >
            </SalesorderRetrieved>
            <PurchaseorderRetrieved
                v-model="query"
                :makeParams="makeQueryParams"
                v-if="query.listdiv == 'po'"
                :listStatus="listStatus"
            >
            </PurchaseorderRetrieved>
        </div>
        <OpModal
            v-if="opEditor.show"
            @close="opEditor.show = false"
            v-slot="{ close, step }"
            :steps="['入力', '確認', '完了']"
            title="案件登録"
        >
            <OpportunityEditor
                :model="opEditor.d"
                :dialog="opEditor"
                mode="create"
                @fin="close"
                @step="step"
                @updated="reload"
                @moveto="moveDetail"
            ></OpportunityEditor>
        </OpModal>
    </div>
</template>

<script>
import OpportunityQuery from "@/views/opportunity/OpportunityQuery.vue";
import OpportunityList from "@/views/opportunity/OpportunityList.vue";
import SalesorderRetrieved from "@/views/opportunity/SalesorderRetrieved.vue";
import PurchaseorderRetrieved from "@/views/opportunity/PurchaseorderRetrieved.vue";
import DateUtils from "@/DateUtils";
import MopSortButton from "@/components/buttons/MopSortButton.vue";
import OpportunityEditor from "@/views/opportunity/OpportunityEditor.vue";
import MopAddButton from "@/components/buttons/MopAddButton.vue";

const DEFAULT_LIMIT = 100;

export default {
    name: "Retrieval",
    components: {
        OpportunityQuery,
        OpportunityList,
        SalesorderRetrieved,
        PurchaseorderRetrieved,
        MopSortButton,
        OpportunityEditor,
        MopAddButton
    },
    data: function() {
        return {
            query: null,
            listStatus: null,
            opEditor: {
                show: false,
                complete: false,
                d: {}
            }
        };
    },
    computed: {
        pages: function() {
            return Math.ceil(this.listStatus.count / this.listStatus.limit);
        },
        currentPage: function() {
            return (
                Math.floor(this.listStatus.offset / this.listStatus.limit) + 1
            );
        }
    },
    methods: {
        initListStatus: function() {
            this.listStatus = {
                showSortModal: false,
                count: 0,
                limit: DEFAULT_LIMIT,
                offset: 0
            };
        },
        initQuery: function() {
            let fyh = DateUtils.getFiscalYearAndHalfsignToday(
                this.getCompany()
            );
            fyh.halfsign = 0;
            this.query = {
                words: "",
                listdiv: "so",
                fiscalyear: { key: fyh.fiscalyear },
                perioddiv: fyh.halfsign,
                limit: DEFAULT_LIMIT,
                offset: 0,
                opOrders: [],
                soOrders: [],
                poOrders: []
            };
            return this.query;
        },
        changePage: function(page) {
            if (page == this.currentPage || page < 1 || page > this.pages) {
                return;
            }
            let ele = document.getElementById("p" + page);
            if (ele) {
                let plist = this.$refs.paginationlist;

                let hw = plist.clientWidth / 2;
                let ex = ele.offsetLeft;
                let mx = plist.scrollWidth - plist.clientWidth;
                let st = Math.ceil(ex - hw + ele.offsetWidth / 2);
                if (st < 0) {
                    st = 0;
                } else if (st > mx) {
                    st = mx;
                }
                plist.scrollLeft = st;
            }
            if (this.query == null) {
                this.query = this.initQuery();
            }
            this.changedQuery(this.query, page);
        },
        reload: function() {
            this.changedQuery(this.query);
        },
        changedQuery: function(query, page) {
            if (page) {
                this.listStatus.offset = (page - 1) * this.listStatus.limit;
            } else {
                this.listStatus.offset = 0;
            }
            this.query.offset = this.listStatus.offset;
            this.query.limit = this.listStatus.limit;
            this.query = { ...query };
            this.$store.commit("retrivequery", this.query);
        },
        makeQueryParams: function(orders) {
            let params = null;
            if (this.query) {
                params = {};
                this.putParam(params, "words");
                this.putParam(params, "department", "department_id");
                this.putParam(params, "customer", "customer_id");
                this.putParam(params, "salesorder_customer", "customer_id");
                this.putParam(params, "user_customer", "customer_id");
                this.putParam(
                    params,
                    "opportunity_department",
                    "department_id"
                );
                this.putParamDataRange(params, "acceptance_date");
                this.putParam(params, "salesorder_status");
                this.putParam(params, "purchaseorder_status");
                this.putParam(params, "salesorder_employee", "employee_id");
                this.putParam(params, "invoice_issued_div");
                this.putParamDataRange(params, "deposit_date");
                this.putParam(params, "deposit_div");
                this.putParam(params, "purchaseorder_customer", "customer_id");
                this.putParam(params, "purchaseorder_employee", "employee_id");
                this.putParamDataRange(params, "delivery_date");
                this.putParam(params, "invoice_receipt_div");
                this.putParamDataRange(params, "payment_date");
                this.putParam(params, "payment_div");
                this.putParam(params, "sales_status");
                this.putParam(params, "costofsales_status");
                this.putParam(params, "acpt_sls");
                this.putParam(params, "delv_cos");
                // if (typeof this.query.fiscalyear == "string") {
                //     this.putParam(params, "fiscalyear");
                // } else {
                //     this.putParam(params, "fiscalyear", "key");
                // }

                let fy = this.getQueryPart("fiscalyear", "key");
                let fp = this.getQueryPart("perioddiv");
                if (fy) {
                    let fpp = 0;
                    if (fp) {
                        // for (let f of fp) {
                        //     fpp += f;
                        // }
                        // if (fpp > 2) {
                        //     fpp = 0;
                        // }
                        fpp = fp;
                    }

                    params["period"] = DateUtils.getRequestFiscalRangeDate(
                        this.getCompany(),
                        fy,
                        fpp
                    );
                }
            }
            if (orders) {
                if (!params) {
                    params = {};
                }
                let p = "";
                for (const ods of orders) {
                    p += "," + ods.key + ":" + ods.desc;
                }
                if (p.length > 0) {
                    params["orders"] = p.substring(1);
                }
            }
            if (!params) {
                params = {};
            }
            params["offset"] = this.listStatus.offset;
            params["limit"] = this.listStatus.limit;
            return params;
        },
        putParam: function(params, pkey, vkey) {
            let p = this.getQueryPart(pkey, vkey);
            if (p) {
                params[pkey] = p;
            }
        },
        getQueryPart: function(pkey, vkey) {
            let ret = this.query[pkey];
            if (ret) {
                if (vkey) {
                    ret = ret[vkey];
                }
            }
            return ret;
        },
        putParamDataRange: function(params, pkey) {
            let rd = this.query[pkey];
            let pm = "";
            if (rd) {
                pm = DateUtils.toRequestRangeDate(rd.start, rd.end);
                if (pm != null && pm != "" && pm != "-") {
                    params[pkey] = pm;
                }
            }
        },
        moveDetail: function(op) {
            this.routePush({
                name: "Opportunity",
                params: { opid: op.opportunity_id }
            });
        }
    },
    created: function() {
        this.query = this.$store.state.retrivequery;
        this.initListStatus();
        if (this.query == null) {
            this.changedQuery(this.initQuery());
        } else {
            if (this.query.limit) {
                this.listStatus.limit = this.query.limit;
            }
            if (this.query.offset) {
                this.listStatus.offset = this.query.offset;
            }
            this.changePage(this.currentPage);
        }
    }
};
</script>

<style scoped>
.op-retrieval-box {
    position: sticky;
    top: 0;
    z-index: 1;
    text-align: center;
    padding-left: 10px;
}
.list-status {
    display: grid;
    grid-template-columns: 80px 180px calc(100% - 520px) 180px 80px;
    align-items: center;
}
.list-status > *:first-child {
    text-align: left;
}
.list-status > *:nth-child(2) {
    font-size: 75%;
    text-align: center;
    opacity: 0.75;
}
.list-status > *:nth-child(6) {
    text-align: center;
}
.list-status > *:nth-child(4) {
    font-size: 75%;
    text-align: center;
    opacity: 0.75;
}
.list-status > *:nth-child(5) {
    text-align: center;
}

/* -------------- */
.pagination-current {
    padding: 5px 10px;
    border: 1px solid var(--border-weak-color);
}
.pagination-link {
    font-size: 80%;
    border: 1px solid var(--border-weak-color);
    padding: 5px 10px;
    cursor: pointer;
    opacity: 0.5;
}
.pagination-links a.disabled:hover {
    color: var(--text-weak-color);
}
.pagination-ctrl {
    font-size: 80%;
    width: 30px;

    text-align: center;
    cursor: pointer;
}
.pagination-ctrl.disabled {
    cursor: default;
}

.pagination-links {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
}
.pagination-list {
    display: block;
    position: relative;
    overflow-x: hidden;
    overflow-y: hidden;
    white-space: nowrap;
    padding: 0 10px;
    margin: 0 10px;
    max-width: calc(100% - 120px);
}
*.pagination-list::-webkit-scrollbar {
    height: 0px !important;
    display: none;
}
.pagination-list li {
    display: inline-block;
    list-style-type: none;
    padding: 0;
    margin: 0;
}
</style>
